@import "bootstrap/bootstrap";
@import "parts/home.scss";
@import "parts/company.scss";

$body-color: #ffff;
$body-bg: #000;

$primary: #ffff;
$danger: #F32013;
$success: #198754;

$primary-100: tint-color($primary, 80%);
$primary-200: tint-color($primary, 60%);
$primary-300: tint-color($primary, 40%);
$primary-400: tint-color($primary, 20%);
$primary-500: $primary;
$primary-600: shade-color($primary, 20%);
$primary-700: shade-color($primary, 40%);
$primary-800: shade-color($primary, 60%);
$primary-900: shade-color($primary, 80%);

$danger-100: tint-color($danger, 80%);
$danger-200: tint-color($danger, 60%);
$danger-300: tint-color($danger, 40%);
$danger-400: tint-color($danger, 20%);
$danger-500: $danger;
$danger-600: shade-color($danger, 20%);
$danger-700: shade-color($danger, 40%);
$danger-800: shade-color($danger, 60%);
$danger-900: shade-color($danger, 80%);

$success-100: tint-color($success, 80%);
$success-200: tint-color($success, 60%);
$success-300: tint-color($success, 40%);
$success-400: tint-color($success, 20%);
$success-500: $success;
$success-600: shade-color($success, 20%);
$success-700: shade-color($success, 40%);
$success-800: shade-color($success, 60%);
$success-900: shade-color($success, 80%);

$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary-600;
$navbar-light-active-color: $primary-600;
$navbar-light-brand-hover-color: $primary-600;

$theme-colors: (
    "primary": $primary,
    "danger": $danger,
    "success": $success,
    "light": $light,
);

@font-face {
    font-family: 'NoirPro-Regular';
    src: url('../font/NoirPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro-SemiBold';
    src: url('../font/NoirPro-SemiBold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro-Light';
    src: url('../font/NoirPro-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro-Bold';
    src: url('../font/NoirPro-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.nav-link {
    color: $primary;
    font-size: 1.2rem;
}

.nav-link:hover {
    color: $primary;
}

.semi-bold {
    font-family: NoirPro-SemiBold;
}

/* ============ nav dropdown ============ */
.navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: right;
    margin-bottom: 8px;
    background-size: 100%;
}

.navbar-close-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='round' d='M6 6 L24 24 M24 6 L6 24'/%3E%3C/svg%3E");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 8px;
    background-size: 100%;
}

.navbar {
    h2 {
        font-size: 2rem;
        font-family: NoirPro-Regular;
    }
}

.navlogo {
    max-height: 45px;
    max-width: 100%;
    width: auto;
    margin-left: 4%;
}


.dropdown-item {
    color: white;
    font-weight: normal !important;
    font-size: 1.2rem;
    font-family: NoirPro-Light;
}

.dropdown-item:hover {
    background-color: $body-bg;
    color: white;
    font-weight: bold !important;
    font-family: NoirPro-Regular;
}

.dropdown-menu {
    translate: -29%;
    text-align: center;
    min-width: 14rem;

    --bs-dropdown-padding-y: 0px;
    --bs-dropdown-bg: $body-bg;
}

.dropdown-toggle:after {
    content: none;
}

.mobile-visible-block {
    display: none !important;
}

.desktop-visible-block {
    display: block !important;
}

.mobile-visible-flex {
    display: none !important;
}

.desktop-visible-flex {
    display: flex !important;
}

/* Responsive Stile für mobile Geräte */
@media (max-width: 991px) {
    .mobile-visible-block {
        display: block !important;
    }

    .desktop-visible-block {
        display: none !important;
    }

    .mobile-visible-flex {
        display: flex !important;
    }

    .desktop-visible-flex {
        display: none !important;
    }

    .no-scroll {
        height: 100vh;
        overflow: hidden;
    }

    .accordion-body ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-left: 1rem;
    }

    .accordion-button,
    .accordion-body {
        background-color: #000 !important;
        color: #fff !important;
        border: none !important;
        font-size: 2rem;
        margin-top: 0rem;
        padding-top: 0rem;
    }

    .accordion-item {
        background-color: #000 !important;
        color: #fff !important;
        border: none !important;
        font-size: 2rem;
        margin-left: 0rem;
        margin-right: 2rem;
        margin-top: 0.5rem;
    }

    .accordion-header {
        background-color: #000 !important;
        color: #fff !important;
        border: none !important;
        font-size: 2.2rem;
    }

    .accordion-button:not(.collapsed) {
        background-color: #000;
        box-shadow: none;
    }

    .accordion-button {
        border-color: #333;
        box-shadow: none !important;
        padding-bottom: 0.5rem;
    }

    .accordion-button:focus {
        box-shadow: none !important;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-family: NoirPro-Light;
    }

    .black-back {
        height: 110vh;
        width: 100vw;
        background-color: black;
        z-index: 1001;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: left;
    }
}


@media all and (min-width: 992px) {
    .non-clickable {
        pointer-events: none;
        cursor: default;
    }

    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .nav-link {}

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}

/* ============ nav dropdown .end// ============ */
body {

    h1,
    h2,
    h3,
    h4 {
        color: $primary;
    }

    a {
        color: $primary;
    }

    font-family: NoirPro-Regular;
    height: 100vh;
    color: $primary;
    background-color: black;
    overflow-x: hidden;
}

.basic-section {
    @media only screen and (max-width: 767px) {
        .container {
            margin-top: 2rem;
            margin-bottom: 3rem;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }

        .contact-half {
            width: 100%;
        }

        .contact-button {
            text-align: left;
        }

        .header-text {
            h1 {
                font-size: calc(1.8rem + 1vw);
            }

            display: flex;
            flex-direction: column;
            margin-top: 6rem;
        }

        .sub-header {
            margin-bottom: calc(1.5rem + 0.8vw);
            text-align: center;
        }

        .content-header {
            font-weight: bolder;
            font-size: 1.5rem;
        }

        .content-subheader {
            font-weight: lighter;
            font-size: 1.1rem;
            font-family: NoirPro-Light;
        }

        .content-text {
            font-weight: lighter;
            font-size: 0.95rem;
            font-family: NoirPro-Light;
        }
        .sub-header-no-bm{
            text-align: center;
            font-size: 1rem;
        }
        .sub-sub-header{
            text-align: center;
            font-size: 0rem;
            visibility: hidden;
            margin-bottom: -2rem;
        }
        .feature-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 auto;
          }
    
          .feature-container .feature-item {
            display: flex;
            align-items: center;
            border-radius: 12px;
            padding: 15px 25px;
            box-shadow: 0 0 12px rgba(255, 255, 255, 0.5);
            color: white;
            font-family: Arial, sans-serif;
            font-size: 16px;
          }
    
          .feature-container .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 8px;
            margin-right: 20px;
          }
    
          .feature-container .icon img {
            max-width: 40px;
            max-height: 40px;
            object-fit: contain;
            object-position: center;
          }
    
          .feature-container .text {
            flex: 1;
          }
    }

    @media only screen and (min-width: 768px) {
        .container {
            margin-top: 2rem;
            margin-bottom: 3rem;
            margin-left: auto;
            margin-right: auto;
            width: 60%;
        }

        .contact-half {
            width: 50%;
        }

        .contact-button {
            text-align: right;
        }

        .header-text {
            h1 {
                font-size: 4.5rem;
            }

            display: flex;
            flex-direction: column;
            margin-top: 6rem;
        }

        .sub-header {
            margin-top: -1rem;
            margin-bottom: 4rem;
            text-align: center;
        }

        .content-header {
            font-weight: bolder;
            font-size: 1.5rem;
        }

        .content-subheader {
            font-weight: lighter;
            font-size: 1.1rem;
            font-family: NoirPro-Light;
        }

        .content-text {
            font-weight: lighter;
            font-size: 0.95rem;
            font-family: NoirPro-Light;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    h1 {
        font-family: NoirPro-SemiBold;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: 0em;
    }

    h5 {
        font-size: 1.35rem;
        font-weight: 400;
        letter-spacing: 0em;
    }

    h6 {
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0em;
    }



    .header-img {
        display: flex;
        flex-direction: row;
        margin-top: 6rem;

        img {
            height: 8.6rem;
            transform: translateY(-10px);
            width: auto;
        }
    }

    .header-dm {
        //margin-top: 6rem;
    }

    strong {
        font-family: NoirPro-SemiBold;
    }

    button {
        border: none;
        color: black;
        background-color: white;
        font-size: 1.5rem;
        font-weight: bolder;
        padding: 5px 12px 5px 12px;
        letter-spacing: 0.08rem;
    }

    .contact-form input,
    .contact-form textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        /* White border */
        border-radius: 0;
        /* Remove border radius */
        margin-bottom: 30px;
        color: #fff;
        /* White input text color */
    }

    .hello {
        background-image: url('/img/subpages/interessantes/hc_dietmar.png');
        /* Pfad zu deinem Bild */
        background-position: center;
        /* Zentriert das Bild */
        background-repeat: no-repeat;
        /* Stellt sicher, dass das Bild nicht wiederholt wird */
        background-size: cover;

        .hellocontent {
            padding-top: 45%;
            padding-bottom: 5%;
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;

            .btn {
                color: black;
                background: #fff;
                border-radius: 0;
                font-size: 1.2rem;
                padding: 8px 15px 8px 15px;
                font-weight: 600;
                letter-spacing: 0.04rem;
            }
        }
    }

    .sub-header-no-bm{
        text-align: center;
        margin-bottom: -5px;
        font-size: 1.7rem;
    }
    .sub-sub-header{
        text-align: center;
        font-size: 1.3rem;
    }
    .feature-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
      }

      .feature-container .feature-item {
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 15px 25px;
        box-shadow: 0 0 12px rgba(255, 255, 255, 0.5);
        color: white;
        font-family: Arial, sans-serif;
        font-size: 16px;
      }

      .feature-container .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        margin-right: 20px;
      }

      .feature-container .icon img {
        max-width: 40px;
        max-height: 40px;
        object-fit: contain;
        object-position: center;
      }

      .feature-container .text {
        flex: 1;
        font-size: 1.2rem;
      }

    @media (max-width: 576px) {
        .hello {
            margin-top: -10%;
            background-image: url('/img/subpages/interessantes/hc_dietmar.png');
            /* Pfad zu deinem Bild */
            background-position: center;
            /* Zentriert das Bild */
            background-repeat: no-repeat;
            /* Stellt sicher, dass das Bild nicht wiederholt wird */
            background-size: contain;

            .hellocontent {
                padding-top: 45%;
                padding-bottom: 5%;
                width: 60%;
                margin-left: 20%;
                margin-right: 20%;

                .btn {
                    color: black;
                    background: #fff;
                    border-radius: 0;
                    font-size: 1.2rem;
                    padding: 8px 15px 8px 15px;
                    font-weight: 600;
                    letter-spacing: 0.04rem;
                }
            }
        }
    }
    

}

strong {
    font-family: NoirPro-Regular;
}


li {
    margin-bottom: 7px;
}

.custom-at::before {
    content: "@";
}

.custom-tel::before {
    content: "7738377";
}

.displayBurgerMenue {
    display: block;
}

@media (max-width: 380px) {
    #logo-text {
        font-size: 20px;
    }
}
@media (max-width: 290px) {
    #logo-text {
        visibility: hidden;
    }
}