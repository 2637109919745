.company {
    .img-fluid {
        transition: transform 0.5s ease;
        &:hover {
            transform: scale(1.05);
        }
    }

    .hello {
        background: url('/img/member/Mitarbeiter-Gruppe-Neu.jpg') center no-repeat;
        background-size: cover;

        .hellocontent {
            padding-top: 45%;
            padding-bottom: 5%;
            width: 60%;
            margin: 0 20%;

            .btn {
                color: black;
                background: #fff;
                border-radius: 0;
                font-size: 1.2rem;
                padding: 8px 15px;
                font-weight: 600;
                letter-spacing: 0.04rem;
            }
        }

        @media (max-width: 768px) {
            background-size: contain;

            .hellocontent {
                width: 80%;
                margin: 0 10%;
                padding-top: 30%;
                padding-bottom: 10%;

                .btn {
                    font-size: 1rem;
                    padding: 6px 12px;
                }
            }
        }

        @media (max-width: 576px) {
            margin-top: -10%;
        }
    }

    p {
        padding: 5px 0 4px;
        margin-bottom: 0;
        font-family: 'NoirPro-Light';
        font-weight: 300;
    }

    h1 {
        font-size: clamp(2.5rem, 5vw, 4.5rem);
        font-weight: 400;
    }

    h5 {
        font-size: clamp(1rem, 3vw, 1.3rem);
        font-weight: 200;
    }

    h6 {
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 0;
    }

    .sub-header {
        margin: -0.8rem 0 3rem;
        font-size: clamp(1rem, 2vw, 1.45rem) !important;
    }

    .my-container {
        width: 90%;
        margin: 0 auto;
        @media (min-width: 768px) {
            width: 80%;
            margin: 0 10%;
        }
    }

    .header {
        text-align: center;
        padding: 20px 0;
    }

    .membercards {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("/img/member/bg-new-black.jpg") center top / contain repeat-y;
            z-index: -1;
        }
    }

    .membercard {
        display: flex;
        justify-content: center;
        color: black;
        


        /* Große Bildschirme (3000px und höher) */
        @media screen and (min-width: 3000px) {
            margin-top: 4%; 
            .teaser {
                z-index: 1;
                height: 18rem;
                width: 50%;
                padding: 3% 5px;
                margin-top: 4rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 1.8rem;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser-span{
                h6{
                    font-size: 1.8rem;
                }
                
            }

            .teaser .teaser-span {
                padding: 7% 3% 10%;
                line-height: 2rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 20rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 12%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 12%;
            }

            .info{
                text-align: center;
    
                h6{
                    font-size: 1.7rem;
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        /* Mittelgroße Bildschirme (2000px bis 2999px) */
        @media screen and (max-width: 2999px) and (min-width: 2000px) {
            margin-top: 5%; 
            .teaser {
                z-index: 1;
                height: 18rem;
                width: 50%;
                padding: 3% 5px;
                margin-top: 3rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 1.6rem;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser-span{
                h6{
                    font-size: 1.7rem;
                }
                
            }

            .teaser .teaser-span {
                padding: 7% 3% 10%;
                line-height: 2rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 18rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 12%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 12%;
            }

            .info{
                text-align: center;
    
                h6{
                    font-size: 1.7rem;
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        /* Große Laptops und Desktops (1600px bis 1999px) */
        @media screen and (max-width: 1999px) and (min-width: 1600px) {
            margin-top: 5%; 
            .teaser {
                z-index: 1;
                height: 18rem;
                width: 50%;
                padding: 3% 5px;
                margin-top: 1.5rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 1.5rem;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser-span{
                h6{
                    font-size: 1.6rem;
                }
                
            }

            .teaser .teaser-span {
                padding: 7% 3% 10%;
                line-height: 1.5rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 15rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 12%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 12%;
            }

            .info{
                text-align: center;
    
                h6{
                    font-size: 1.5rem;
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        /* Standard-Desktop-Bildschirme (1300px bis 1599px) */
        @media screen and (max-width: 1599px) and (min-width: 1300px) {
            margin-top: 7%; 
            .teaser {
                z-index: 1;
                height: 18rem;
                width: 50%;
                padding: 3% 5px;
                margin-top: 1.5rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 1.5rem;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser-span{
                h6{
                    font-size: 1.6rem;
                }
                
            }

            .teaser .teaser-span {
                padding: 7% 3% 10%;
                line-height: 1.5rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 15rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 12%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 12%;
            }
            margin-top: 7%; 
            .info{
                text-align: center;
    
                h6{
                    font-size: 1.5rem;
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        /* Tablets und kleine Laptops (992px bis 1299px) */
        @media screen and (max-width: 1299px) and (min-width: 992px) {
            margin-top: 7%; 
            .teaser {
                z-index: 1;
                height: 14rem;
                width: 40%;
                padding: 3% 5px;
                margin-top: 1.5rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 0.85rem;
                font-weight: 100;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser .teaser-span {
                padding: 7% 4% 10%;
                line-height: 1rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 11rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 20%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 20%;
            }
            .info{
                font-family: NoirPro-Light;
                font-size: 0.85rem;
                text-align: center;
    
                h6{
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        /* Kleine Tablets (840px bis 991px) */
        @media screen and (max-width: 991px) and (min-width: 840px) {
            margin-top: 7%; 
            .teaser {
                z-index: 1;
                height: 12rem;
                width: 45%;
                padding: 3% 5px;
                margin-top: 1.5rem;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                font-size: 0.7rem;
                text-align: left;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .teaser .teaser-span {
                padding: 7% 2% 10%;
                line-height: 1rem;
            }

            .right, .left {
                width: 75%;
            }

            .card {
                width: 10rem;
                padding: 5px;
                background-color: #ffffff;
                box-shadow: 10px 0 15px rgba(0, 0, 0, 0.2);
                font-size: 1.7vh;
                border-radius: 0;
                z-index: 2;
            }

            .right .card {
                box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            }

            .right {
                justify-content: flex-end;
                margin-right: 25%;
            }

            .left {
                justify-content: flex-start;
                margin-left: 25%;
            }

            .info{
                font-family: NoirPro-Light;
                font-size: 0.85rem;
                text-align: center;
    
                h6{
                    font-family: NoirPro-Regular;
                    font-weight: 900;
                }
            }
        }

        

        @media (max-width: 840px) {
            margin-top: 7%; 
            text-align: center;
            flex-direction: column;
            .teaser { display: none; }
            .card { 
                width: 75%;
                img{
                    margin-top: 5%;
                } 
            }

            .left, .right {
                margin: 0 10% !important;
                width: 75% !important;
                justify-content: center !important;
            }

            .info-sub {
                display: block !important;
                font-weight: 100;
                font-size: 1.1rem;
                font-family: 'NoirPro-Regular';
                padding: 0 7%;
            }
        }

        @media (max-width: 576px) {
            margin-top: 7%; 
            margin-bottom: 7%;
            text-align: center;
            .card { width: 90%; 
                img{
                    margin-top: 5%;
                }
            }

            .left, .right {
                margin: 0;
                width: 100%;
                justify-content: center;
            }

            .info-sub {
                font-size: 1rem;
                padding: 0 5%;
            }
        }

        .card img {
            width: 100%;
            height: auto;
            margin-bottom: 5px;
        }
    }
}
