.home {
    width: 100%;

    .start {
        width: 100%;
        height: 90vh;

        .video-background {
            position: relative;
            width: 100%;
            height: 100vh;
        }

        .video-background video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80%;
            object-fit: contain;
            z-index: -1;
        }


        .video-background::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.50);
            z-index: 1;
        }

        .content {
            position: absolute;
            z-index: 1;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .btn {
            color: black;
            background: #fff;
            border-radius: 0;
            font-size: 1.4rem;
            padding: 8px 15px 8px 15px;
            font-weight: 600;
        }

        h2 {
            //margin-top: 7rem;
            margin-top: 0px;
            font-size: 2.8rem;
        }

        @media (max-width: 767px) {
            

            h2 {
                //margin-top: 7rem;
                margin-top: 2rem;
                font-size: 2rem;
            }

            h3 {
                font-size: calc(1rem + 0.6vw);
            }

            .content {
                top: 20vh;
            }

            .video-background {
                bottom: 15vh;
            }
        }

        .scroll-down-icon {
            @media (max-height: 520px) {
                opacity: 0;
            }
            z-index: 1001;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            bottom: 2vh;
            width: 6vh;
            height: 6vh;
        }
    }

    .hammerdigitalfeatures {
        margin-top: 6rem;
        margin-bottom: 6rem;

        .row {
            margin-right: 0;
            height: 28rem;
        }

        .col-6 {
            padding-top: 5%;
            padding-bottom: 5%;
        }

        .h4-white {
            font-size: 2.5rem;
            font-weight: 900;
            margin-right: 4px;
        }

        .h4-black {
            color: black;
            font-size: 2.5rem;
            font-weight: 900;
            margin-left: 3px;
        }

        .hd-line-white {
            font-weight: 600;
            font-size: 4.5rem;
            margin-right: 4px;
        }

        .hd-line-black {
            font-weight: 600;
            font-size: 4.5rem;
            color: black;
            margin-left: 3px;
        }

        .col-6:first-child {
            background-image: url('/img/home/3d-network-particle-flow-background.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            text-align: right;
            color: #fff;
            padding-right: 0%;
        }

        .col-6:last-child {
            background-image: url('/img/home/3d-network-particle-flow-background2.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            text-align: left;
            padding-left: 0%;

            .h1 {
                color: black !important;
            }

            .h4 {
                color: black !important;
            }
        }

        @media (max-width: 767px) {
            .h4-white {
                font-size: 2rem;
                font-weight: 900;
                margin-right: 0px;
            }

            .h4-black {
                color: black;
                font-size: 2rem;
                font-weight: 900;
                margin-left: 0px;
            }

            .hd-line-white {
                font-weight: 500;
                font-size: 2.5rem;
                margin-right: 0px;
            }

            .hd-line-black {
                font-weight: 500;
                font-size: 2.5rem;
                color: black;
                margin-left: 0px;
            }
        }
    }

    .hello {
        background-image: url('/img/member/Mitarbeiter-Gruppe-Neu.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .hellocontent {
            padding-top: 45%;
            padding-bottom: 5%;
            margin-top: 5%;
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;

            h1 {
                font-size: 6rem;
                font-weight: 600;
                transform: translateX(-3px);
            }

            .btn {
                color: black;
                background: #fff;
                border-radius: 0;
                font-weight: 600;
                font-size: 1.55rem;
                padding: 5px;
                letter-spacing: 0.08rem;
            }

            @media (max-width: 767px) {
                padding-top: 40%;
                margin-left: 15%;
                margin-right: 15%;

                h1 {
                    font-size: 3rem;
                    font-weight: 600;
                }

                h2 {
                    font-size: 1rem;
                }

                .btn {
                    font-weight: 600;
                    font-size: 1.2rem;
                    padding: 3px;
                    letter-spacing: 0.08rem;
                }
            }
        }
    }

    .invisible {
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    .visible {
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }
}